var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import PhoneProductCard from "./PhoneProductCard.vue";
import PhoneProductCardLoading from "./PhoneProductCardLoading.vue";
import { mapState } from "vuex";
export default Vue.extend({
    components: {
        PhoneProductCard: PhoneProductCard,
        PhoneProductCardLoading: PhoneProductCardLoading,
    },
    apollo: {
        mobilePhoneProducts: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        mobilePhoneProducts {\n          id\n          ...PhoneProductCard_product\n        }\n      }\n      ", "\n    "], ["\n      {\n        mobilePhoneProducts {\n          id\n          ...PhoneProductCard_product\n        }\n      }\n      ", "\n    "])), PhoneProductCard.options.fragments.product),
        mobileDataProducts: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        mobileDataProducts {\n          id\n          ...PhoneProductCard_product\n        }\n      }\n      ", "\n    "], ["\n      {\n        mobileDataProducts {\n          id\n          ...PhoneProductCard_product\n        }\n      }\n      ", "\n    "])), PhoneProductCard.options.fragments.product),
        contentPage: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      {\n        contentPage(key: \"mobile\") {\n          originalBannerImageUrl\n        }\n      }\n    "], ["\n      {\n        contentPage(key: \"mobile\") {\n          originalBannerImageUrl\n        }\n      }\n    "]))),
    },
    computed: mapState(["locale"]),
});
var templateObject_1, templateObject_2, templateObject_3;
