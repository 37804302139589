var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import FeatureList from "../../ui/FeatureList.vue";
import { moneyFormat } from "../../../utils/filters";
export default Vue.extend({
    components: {
        FeatureList: FeatureList,
    },
    fragments: {
        product: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PhoneProductCard_product on MobileAndDataProduct {\n        id\n        slug\n        name\n        heading\n        summary\n        features {\n          description\n          icon\n        }\n        thumbnailUrl\n        pricePerMonth {\n          currency\n          amount\n        }\n      }\n    "], ["\n      fragment PhoneProductCard_product on MobileAndDataProduct {\n        id\n        slug\n        name\n        heading\n        summary\n        features {\n          description\n          icon\n        }\n        thumbnailUrl\n        pricePerMonth {\n          currency\n          amount\n        }\n      }\n    "]))),
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        heading: function () {
            if (!this.product) {
                return undefined;
            }
            return (moneyFormat(this.product.pricePerMonth) + " " + this.product.heading);
        },
    },
});
var templateObject_1;
